import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // 重定向
    redirect: '/HomePage',
    name: 'HomeView',
  },
  {
    path: '/HomePage',
    name: 'HomePage',
    component: () => import('../components/HomePage.vue')
  },
  {
    path: '/ProductDetails',
    name: 'ProductDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ProductDetails.vue'),
  },
  {
    path: '/ImpactDetection',
    name: 'ImpactDetection',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ImpactDetection.vue')
  },
  {
    path: '/ModelAnimal',
    name: 'ModelAnimal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ModelAnimal.vue')
  },
  {
    path: '/ExperimentCase',
    name: 'ExperimentCase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ExperimentCase.vue'),
  },
  {
    path: '/BusinessProcess',
    name: 'BusinessProcess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/BusinessProcess.vue')
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/AboutUs.vue')
  },
  {
    path: '/MedicalIntermediate',
    name: 'MedicalIntermediate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/MedicalIntermediate.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // 滚动到页面顶部
    return { x: 0, y: 0 }
  }
})

// router.beforeEach((to, from, next) => {
//   if () {

//   }
// })

export default router
