var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"navbar",staticClass:"navigationBar",class:{
  'navbar-white': _vm.isNavbarWhite || this.$route.path !== '/HomePage',
  active: _vm.menuIsShow,
}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"logo",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{attrs:{"src":require("../assets/img/模式动物实验服务网.png"),"alt":""}})]),_c('ul',{staticClass:"ipadStyle",on:{"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}},[_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('home-page')}}},[_vm._v("首页")]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('company-intro', '/AboutUs')}}},[_vm._v(" 关于我们 ")]),_c('li',[_c('el-dropdown',{on:{"command":_vm.handleClickDropdown},scopedSlots:_vm._u([{key:"dropdown",fn:function(){return [_c('el-dropdown-menu',{staticClass:"xl"},_vm._l((_vm.dropdown),function(item){return _c('el-dropdown-item',{key:item.id,attrs:{"command":item.path}},[_vm._v(_vm._s(item.name))])}),1)]},proxy:true}])},[_c('span',{staticClass:"el-dropdown-link",class:{
            a: _vm.isNavbarWhite || this.$route.path !== '/HomePage',
            active: _vm.menuIsShow,
          }},[_vm._v(" 实验外包服务 ")])])],1),_c('li',{on:{"click":function($event){return _vm.scrollToCompanyIntro('Medical-intermediate', '/MedicalIntermediate')}}},[_vm._v(" 医药中间体 ")]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('experiment-case', '/ExperimentCase')}}},[_vm._v(" 实验案例 ")]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('business-process', '/BusinessProcess')}}},[_vm._v(" 业务流程 ")]),_c('li',{staticStyle:{"display":"none"},on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('core-advantage')}}},[_vm._v(" 核心优势 ")]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('contact-us')}}},[_vm._v("联系我们")])]),_c('i',{staticClass:"iconfont icon-caidan menu",on:{"click":_vm.handleClickMenu}})]),_c('ul',{staticClass:"phoneStyle"},[_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('home-page', '', 'phoneStyle')}}},[_c('span',[_vm._v("首页")]),_c('i',{staticClass:"iconfont icon-arrow-right"})]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('company-intro', '/AboutUs', 'phoneStyle')}}},[_c('span',[_vm._v("关于我们")]),_c('i',{staticClass:"iconfont icon-arrow-right"})]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('Product-summary', '/ModelAnimal', 'phoneStyle')}}},[_c('span',[_vm._v("模式动物")]),_c('i',{staticClass:"iconfont icon-arrow-right"})]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro(
        'Product-summary',
        '/ImpactDetection',
        'phoneStyle'
      )}}},[_c('span',[_vm._v("影像检测")]),_c('i',{staticClass:"iconfont icon-arrow-right"})]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro(
        'Product-summary',
        '/MedicalIntermediate',
        'phoneStyle'
      )}}},[_c('span',[_vm._v("医药中间体")]),_c('i',{staticClass:"iconfont icon-arrow-right"})]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro(
        'experiment-case',
        '/ExperimentCase',
        'phoneStyle'
      )}}},[_c('span',[_vm._v("实验案例")]),_c('i',{staticClass:"iconfont icon-arrow-right"})]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro(
        'business-process',
        '/BusinessProcess',
        'phoneStyle'
      )}}},[_c('span',[_vm._v("业务流程")]),_c('i',{staticClass:"iconfont icon-arrow-right"})]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('core-advantage', '', 'phoneStyle')}}},[_c('span',[_vm._v("核心优势")]),_c('i',{staticClass:"iconfont icon-arrow-right"})]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToCompanyIntro('contact-us', '', 'phoneStyle')}}},[_c('span',[_vm._v("联系我们")]),_c('i',{staticClass:"iconfont icon-arrow-right"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }