<template>
  <div id="app">
    <Header />
    <HomeView />
    <Footer />
  </div>
</template>



<script>
(function (b, a, e, h, f, c, g, s) {
  b[h] = b[h] || function () { (b[h].c = b[h].c || []).push(arguments) };
  b[h].s = !!c; g = a.getElementsByTagName(e)[0]; s = a.createElement(e);
  s.src = "//s.union.360.cn/" + f + ".js"; s.defer = !0; s.async = !0; g.parentNode.insertBefore(s, g)
})(window, document, "script", "_qha", 560924, false);
</script>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import HomeView from './views/HomeView.vue';




export default {
  components: {
  
    Header,
    Footer,
    HomeView,
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  list-style: none;
}

body {
  width: 100vw;
  /* height: 100vh; */
}

#app {
  width: 100%;
  /* height: 100%; */
}
</style>
