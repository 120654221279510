<template>
  <!-- 导航栏 -->
  <div class="navigationBar" ref="navbar" :class="{
    'navbar-white': isNavbarWhite || this.$route.path !== '/HomePage',
    active: menuIsShow,
  }">
    <div class="box">
      <div class="logo" @click="$router.push('/')" style="cursor: pointer">
        <img src="../assets/img/模式动物实验服务网.png" alt="" />
      </div>
      <ul @mouseover="handleMouseOver" @mouseleave="handleMouseLeave" class="ipadStyle">
        <li @click.prevent="scrollToCompanyIntro('home-page')">首页</li>
        <li @click.prevent="scrollToCompanyIntro('company-intro', '/AboutUs')">
          关于我们
        </li>
        <!-- <li
          @click.prevent="
            scrollToCompanyIntro('Product-summary', '/ProductDetails')
          "
        >
          实验外包服务
        </li> -->
        <li>
          <el-dropdown @command="handleClickDropdown">
            <span class="el-dropdown-link" :class="{
              a: isNavbarWhite || this.$route.path !== '/HomePage',
              active: menuIsShow,
            }">
              实验外包服务
            </span>
            <template #dropdown>
              <el-dropdown-menu class="xl">
                <el-dropdown-item v-for="item in dropdown" :key="item.id" :command="item.path">{{ item.name
                  }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li @click="
          scrollToCompanyIntro('Medical-intermediate', '/MedicalIntermediate')
          ">
          医药中间体
        </li>
        <li @click.prevent="
          scrollToCompanyIntro('experiment-case', '/ExperimentCase')
          ">
          实验案例
        </li>
        <li @click.prevent="
          scrollToCompanyIntro('business-process', '/BusinessProcess')
          ">
          业务流程
        </li>
        <li @click.prevent="scrollToCompanyIntro('core-advantage')" style="display: none">
          核心优势
        </li>
        <li @click.prevent="scrollToCompanyIntro('contact-us')">联系我们</li>
        <!-- <li @click="handleClickSearchIsShow">
          <i class="iconfont icon-fangdajing" style="font-size: 26px"></i>
        </li> -->
      </ul>
      <i class="iconfont icon-caidan menu" @click="handleClickMenu"></i>
    </div>

    <ul class="phoneStyle">
      <li @click.prevent="scrollToCompanyIntro('home-page', '', 'phoneStyle')">
        <span>首页</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
      <li @click.prevent="
        scrollToCompanyIntro('company-intro', '/AboutUs', 'phoneStyle')
        ">
        <span>关于我们</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
      <li @click.prevent="
        scrollToCompanyIntro('Product-summary', '/ModelAnimal', 'phoneStyle')
        ">
        <span>模式动物</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
      <li @click.prevent="
        scrollToCompanyIntro(
          'Product-summary',
          '/ImpactDetection',
          'phoneStyle'
        )
        ">
        <span>影像检测</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
      <li @click.prevent="
        scrollToCompanyIntro(
          'Product-summary',
          '/MedicalIntermediate',
          'phoneStyle'
        )
        ">
        <span>医药中间体</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
      <li @click.prevent="
        scrollToCompanyIntro(
          'experiment-case',
          '/ExperimentCase',
          'phoneStyle'
        )
        ">
        <span>实验案例</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
      <li @click.prevent="
        scrollToCompanyIntro(
          'business-process',
          '/BusinessProcess',
          'phoneStyle'
        )
        ">
        <span>业务流程</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
      <li @click.prevent="
        scrollToCompanyIntro('core-advantage', '', 'phoneStyle')
        ">
        <span>核心优势</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
      <li @click.prevent="scrollToCompanyIntro('contact-us', '', 'phoneStyle')">
        <span>联系我们</span>
        <i class="iconfont icon-arrow-right"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      IsMouseLeave: true, //鼠标是否移出
      headerHeight: 0,
      isNavbarWhite: false, // 默认背景颜色为透明
      menuIsShow: false, // 默认菜单不显示
      dropdown: [
        { name: "模式动物", id: 1, path: "/ModelAnimal" },
        { name: "影像检测", id: 2, path: "/ImpactDetection" },
      ],
    };
  },
  mounted() {
    this.getHeaderHeight();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    // //////以下是pc端的逻辑
    getHeaderHeight() {
      const navigationBar = this.$refs.navbar;
      const style = window.getComputedStyle(navigationBar);
      this.headerHeight = parseFloat(style.height);
      this.$store.dispatch("setHeaderHeight", this.headerHeight);
    },
    handleClickDropdown(val) {
      if (this.$route.path != val) {
        this.$router.push(val);
      }
    },

    handleResize() {
      // 只在组件销毁前调用一次，以避免无限循环
      this.getHeaderHeight();
    },

    handleScroll() {
      // 当页面滚动时，检查滚动距离
      const scrollDistance =
        window.scrollY || document.documentElement.scrollTop;
      // 如果滚动距离大于某个值，改变导航栏背景颜色
      if (
        scrollDistance > 0 &&
        (this.$route.path === "/" || this.$route.path === "/HomePage")
      ) {
        this.isNavbarWhite = true;
      }

      if (this.IsMouseLeave == true && scrollDistance == 0) {
        this.isNavbarWhite = false;
      }
    },

    handleMouseOver() {
      this.IsMouseLeave = false;
      this.isNavbarWhite = true;
    },

    handleMouseLeave() {
      if (window.scrollY > 0) {
        this.IsMouseLeave = true;
        this.isNavbarWhite = true;
      } else {
        this.isNavbarWhite = false;
        this.IsMouseLeave = true;
      }
    },
    scrollToCompanyIntro(val, path, type = "") {
      if (type == "phoneStyle") {
        this.handleClickMenu();
      }
      if (
        val !== "home-page" &&
        val !== "core-advantage" &&
        val !== "contact-us" &&
        this.$route.path !== path
      ) {
        this.$router.push(path);
      }
      if (val == "contact-us") {
        const element = document.getElementById(val);
        if (element) {
          const scrollPosition =
            element.offsetTop - this.$store.getters.getMarginTop;
          window.scrollTo({ top: scrollPosition, behavior: "smooth" });
        }
      }

      if (val == "core-advantage" || val == "home-page") {
        // 首先检查当前路由是否已经是 '/HomePage'
        if (this.$route.path !== "/HomePage") {
          // 如果不是，先跳转到 '/HomePage'
          this.$router.push("/HomePage").then(() => {
            // 获取槽点
            const element = document.getElementById(val);
            if (element) {
              // 滚动距离减去导航栏的高度
              const scrollPosition =
                element.offsetTop - this.$store.getters.getMarginTop;
              // 最终滚动的位置
              window.scrollTo({ top: scrollPosition, behavior: "smooth" });
            }
          });
        } else {
          // 如果已经是 '/HomePage'，直接滚动
          const element = document.getElementById(val);
          if (element) {
            // 滚动距离减去导航栏的高度
            const scrollPosition =
              element.offsetTop - this.$store.getters.getMarginTop;
            // 最终滚动的位置
            window.scrollTo({ top: scrollPosition, behavior: "smooth" });
          }
        }
      }
    },

    // ////以下是手机端和ipad端的逻辑
    handleClickMenu() {
      this.menuIsShow = !this.menuIsShow;
      let navigationBar = document.getElementsByClassName("navigationBar")[0];
      let phoneStyle = document.getElementsByClassName("phoneStyle")[0];
      let box = document.getElementsByClassName("box")[0];
      if (this.menuIsShow) {
        navigationBar.style.height = "100" + "%";
        phoneStyle.style.display = "block";
        box.style.height = "10" + "%";
      }
      if (!this.menuIsShow) {
        navigationBar.style.height = "10" + "%";
        phoneStyle.style.display = "none";
        box.style.height = "100" + "%";
      }
    },
  },
};
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/deep/.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  font-size: 16px;

  &:hover {
    color: #000;
  }
}

.xl {
  z-index: 10000 !important;
  font-size: 20px !important;

  li {
    font-size: 16px;
    padding: 0 10px;
  }
}

.a {
  color: #000;
}

.navbar-white {
  // 透明白色
  background: #fff !important;
  box-shadow: 6px 2px 5px rgba(0, 0, 0, 0.5);
  color: #000 !important;
}

.menu {
  display: none;
}

/* 导航栏盒子 */
.navigationBar {
  transition: background 0.3s linear;
  background-color: rgba(255, 255, 255, 0);
  z-index: 9999;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 113px;
  padding: 0 20px;
  color: #fff;
  overflow: hidden;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  /* 列表 */
  .ipadStyle {
    display: flex;
    flex: 6;
    padding: 0 2%;
    justify-content: space-between;
    margin-left: 10%;

    li {
      list-style: none;
      cursor: pointer;
    }
  }

  /* logo盒子 */
  .logo {
    margin-left: 5%;
    flex: 3;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    /* logo图片 */
    img {
      width: 100%;
    }
  }
}

.phoneStyle {
  display: none;
}

@media (min-width: 900px) and(max-width:1023px) {
  /deep/.el-dropdown-link {
    cursor: pointer;
    color: #000;
    font-size: 16px;

    &:hover {
      color: #000;
    }
  }

  /* 导航栏盒子 */
  .navigationBar {
    background: #fff;
    box-shadow: 6px 2px 5px rgba(0, 0, 0, 0.5);
    color: #000;

    /* 列表 */
    .ipadStyle {
      flex: 7;
      margin-left: 5%;
    }

    .logo {
      margin-left: 5%;
    }
  }
}

@media (max-width: 899px) {
  .navigationBar {
    // transition: height 0.3s linear;
    height: 10%;
    background: #fff;
    box-shadow: 6px 2px 5px rgba(0, 0, 0, 0.5);
    color: #000;

    .logo {
      flex: 5;
    }

    .ipadStyle {
      display: none;
    }

    .menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 5;
      height: 100%;
      text-align: end;
      padding-right: 4%;
      font-size: 30px;
    }

    .phoneStyle {
      line-height: 400%;
      padding: 0 4%;
      margin-top: 4%;

      li {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
      }
    }
  }
}
</style>
