
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    product: [
      {
        id: 1, name: '模式动物', path: '/ModeAnimal',
        ProductsContain: [
          {
            name: '基因编辑动物', id: 1,
            contain: [
              { name: '成品模型鼠出售', id: 1 },
              { name: '全身性基因敲除', id: 2 },
              { name: '条件性基因敲除', id: 3 },
              { name: '转基因服务', id: 4 }
            ]
          },
          {
            name: '疾病动物模型（大型或小型实验动物）', id: 2,
            contain: [
              { name: '小动物心血管疾病', id: 1 },
              { name: '小动物代谢类疾病', id: 2 },
              { name: '肿瘤', id: 3 },
              { name: '小动物呼吸系统疾病', id: 4 },
              { name: '小动物骨科疾病', id: 5 }
            ]
          },
          {
            name: '动物净化扩繁', id: 3,
            contain: [
              { name: '小鼠生物净化与快速繁育服务', id: 1 },
              { name: '小鼠冷冻保种与复苏服务', id: 2 }
            ]
          }
        ]
      },
      {
        id: 2, name: '影像检测', path: '/ModeAnimal',
        ProductsContain: [
          { name: 'Micro-CT', id: 1 },
          { name: '小动物活体光学成像', id: 2 },
          { name: '数字化X线摄影-DR', id: 3 },
          { name: 'X线计算机断层成像-CT', id: 4 },
          { name: '磁共振像-MRI', id: 5 }
        ]
      },
      {
        id: 3, name: '医药中间体', path: '/ModeAnimal',
        ProductsContain: [
          { name: '消化类', id: 1 },
          { name: '抗肿瘤类', id: 2 },
          { name: '抗病毒类', id: 3 }
        ]
      },
    ],
    productImgs: [
      {
        id: 1,
        img: require('../assets/product-img/product-img1.png')
      },
      {
        id: 2,
        img: require('../assets/product-img/product-img2.png')
      },
      {
        id: 3,
        img: require('../assets/product-img/product-img3.png')
      },
      {
        id: 4,
        img: require('../assets/product-img/product-img4.png')
      },
      {
        id: 5,
        img: require('../assets/product-img/product-img5.png')
      },
      {
        id: 6,
        img: require('../assets/product-img/product-img6.png')
      },
      {
        id: 7,
        img: require('../assets/product-img/product-img7.png')
      },
      {
        id: 8,
        img: require('../assets/product-img/product-img8.png')
      },
      {
        id: 9,
        img: require('../assets/product-img/product-img9.png')
      },
      {
        id: 10,
        img: require('../assets/product-img/product-img10.png')
      },
      {
        id: 11,
        img: require('../assets/product-img/product-img11.png')
      },
      {
        id: 12,
        img: require('../assets/product-img/product-img12.png')
      },
      {
        id: 13,
        img: require('../assets/product-img/product-img13.png')
      },
      {
        id: 14,
        img: require('../assets/product-img/product-img14.png')
      },
      {
        id: 15,
        img: require('../assets/product-img/product-img15.png')
      },
      {
        id: 16,
        img: require('../assets/product-img/product-img16.png')
      },
      {
        id: 17,
        img: require('../assets/product-img/product-img17.png')
      },
      {
        id: 18,
        img: require('../assets/product-img/product-img18.png')
      },
      {
        id: 19,
        img: require('../assets/product-img/product-img19.png')
      },
      {
        id: 20,
        img: require('../assets/product-img/product-img20.png')
      },
      {
        id: 21,
        img: require('../assets/product-img/product-img21.png')
      },
      {
        id: 22,
        img: require('../assets/product-img/product-img22.png')
      },
      {
        id: 23,
        img: require('../assets/product-img/product-img23.png')
      },
      {
        id: 24,
        img: require('../assets/product-img/product-img24.png')
      },
      {
        id: 25,
        img: require('../assets/product-img/product-img25.png')
      },
      {
        id: 26,
        img: require('../assets/product-img/product-img26.png')
      },
      {
        id: 27,
        img: require('../assets/product-img/product-img27.png')
      },
      {
        id: 28,
        img: require('../assets/product-img/product-img28.png')
      },
      {
        id: 29,
        img: require('../assets/product-img/product-img29.png')
      },
      {
        id: 30,
        img: require('../assets/product-img/product-img30.png')
      },
      {
        id: 31,
        img: require('../assets/product-img/product-img31.png')
      },
      {
        id: 32,
        img: require('../assets/product-img/product-img32.png')
      },
      {
        id: 33,
        img: require('../assets/product-img/product-img33.png')
      },
      {
        id: 34,
        img: require('../assets/product-img/product-img34.png')
      },
      {
        id: 35,
        img: require('../assets/product-img/product-img35.png')
      },
      {
        id: 36,
        img: require('../assets/product-img/product-img36.png')
      },
      {
        id: 37,
        img: require('../assets/product-img/product-img37.png')
      },
      {
        id: 38,
        img: require('../assets/product-img/product-img38.png')
      },
      {
        id: 39,
        img: require('../assets/product-img/product-img39.png')
      },
    ],
    // 导航栏的高度
    marginTop: 0,
    // 搜索框的显示和隐藏
    searchShow: false,
    // 用户输入的内容
    value: '',
  },
  getters: {
    getMarginTop: state => state.marginTop,
  },
  mutations: {
    setMarginTop(state, date) {
      state.marginTop = date;
    },
    handleScrollSearch(state) {
      state.searchShow = false;
    },
    handleSearchContent(state, data) {
      state.value = data;
    }
  },
  actions: {
    setHeaderHeight({ commit }, data) {
      commit('setMarginTop', data)
    },
    getSearchIsShow({ commit }, data) {
      commit('handleSearchContent', data)
    }
  },
  modules: {
  }
})
