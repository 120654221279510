import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入 core-js 的正确方式
import 'core-js/stable';
import '../font_4529342_k3tbbgc0k6/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
